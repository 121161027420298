<template>
  <div class="d-flex bg-default">
    <section class="list px-3" >
      <div class="heads">
        <v-text-field
          solo
          flat
          label="Cari"
          rounded
          append-icon="mdi-magnify"
          hide-details
          required
        ></v-text-field>
      </div>
      <div class="temp-list" v-if="listFeedback">
        <div
          class="post-status-temp mt-4"
          :class="
            feed.judulFeedback == 'Bug'
              ? 'item _bug'
              : feed.judulFeedback == 'Komentar'
              ? 'item _comment'
              : 'item _etc'
          "
          v-for="(feed, q) in listFeedback"
          :key="q"
          @click="feedDetail(feed)"
        >
          <div class="pa-2">
            <section class="d-flex">
              <v-avatar color="indigo" size="40" class="mr-2">
                <span class="white--text font-weight-bold" v-if="feed.namaUser">{{
                  feed.namaUser[0].toUpperCase()
                }}</span>
              </v-avatar>
              <h4 >{{ feed.namaUser }}</h4>
            </section>
            <section class="d-flex">
              <div style="width: 50px"></div>
              <p class="pa-0 grey--text">{{ feed.judulFeedback }}</p>
            </section>
          </div>
        </div>
        <div
        v-if="listFeedback"
        v-observe-visibility="handleScroll"
        >
        </div>
      </div>
    </section>
    <section class="feed mx-3" v-if="feedbackById">
      <div class="heads"></div>
      <v-card flat>
        <v-toolbar flat height="50px">
          <v-avatar color="indigo" size="40" class="mr-2">
            <span class="white--text font-weight-bold">{{
              feedbackById.namaUser[0]
            }}</span>
          </v-avatar>
          <h4>{{ feedbackById.namaUser }}</h4>
        </v-toolbar>
        <section class="px-3 pb-3">
          <h3>Tipe feeds : {{ feedbackById.judulFeedback }}</h3>
          <p v-html="feedbackById.isiFeedback"></p>
          <footer>
            <v-icon>mdi-clock</v-icon>
            <small>{{ moment(feedbackById.createdAt).format("HH.ss") }}</small>
            | <v-icon>mdi-calendar</v-icon>
            <small>{{
              moment(feedbackById.createdAt).format("DD-MM-YYYY")
            }}</small>
          </footer>
        </section>
      </v-card>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "listFeedback",
  computed: {
    ...mapState({
      listFeedback: state => state.feedback.listFeedback,
      feedbackById: state => state.feedback.feedbackbyid,
      lastFeed: state => state.feedback.lastFeed,
    })
  },
  data() {
    return {
      moment: moment,
      page: 1,
      limit: 5,
    };
  },
  mounted() {
    this.$store.dispatch("feedback/listFeedback", { page: this.page, per_page: this.limit });
  },
  methods: {
    feedDetail(item) {
      this.$store.dispatch("feedback/FeedbackDetail", item.idFeedback);
    },
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.page <= this.lastFeed) {
          this.page++;
          console.log(this.listFeedback);
          this.$store.dispatch("feedback/listFeedback", { 
            per_page: this.limit,
            page: this.page, 
            });
        }
      }
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
  height: 90vh;
  overflow: hidden;
}
.list {
  width: 30%;
}
.feed {
  width: 70%;
}
.item {
  width: 100%;
  height: 85px;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.item:hover {
  background: #c8ddfc;
  transition: 0.5s;
}
._bug {
  border-left: 3px solid red;
}
._comment {
  border-left: 3px solid blue;
}
._etc {
  border-left: 3px solid green;
}
.heads {
  height: 50px;
  margin-bottom: 15px;
}
.temp-list {
  height: 79vh;
  overflow-y: scroll;
}
</style>
